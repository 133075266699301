var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',{attrs:{"title":'服务动态'}},[_c('article',{attrs:{"id":"page-news"}},[_c('div',{staticClass:"public-blue-bg news-banner-bg"},[_c('div',{staticClass:"container"},[_c('h1',[_vm._v("公共法律服务动态")]),_c('div',{staticClass:"text"},[_vm._v(" 公共法律服务是政府公共职能的重要组成部分，是保障和改善民生的重要举措，是全面依法治国的基础性、服务性和保障性工作。 ")])])]),_c('section',{staticClass:"container container-main"},[_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-lg-6 col-md-6 col-xs-6 pd-r-10 pl-0"},[_c('section',{staticClass:"swiper-container comp-focus",attrs:{"id":"page-index-focus"}},[_c('Collection',{attrs:{"name":"post","query":{ sort_no: '100'},"limit":9},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var collection = ref.collection;
return [_c('a-carousel',{attrs:{"arrows":""}},[_c('div',{staticClass:"custom-slick-arrow",attrs:{"slot":"prevArrow"},slot:"prevArrow"},[_c('a-icon',{attrs:{"type":"left"}})],1),_c('div',{staticClass:"custom-slick-arrow",attrs:{"slot":"nextArrow"},slot:"nextArrow"},[_c('a-icon',{attrs:{"type":"right"}})],1),_vm._l((collection),function(value){return _c('div',{key:value.post_id,staticClass:"swiper-slide"},[_c('router-link',{attrs:{"to":("/article/detail/" + (value.post_id)),"title":value.post_title}},[_c('img',{attrs:{"src":_vm.getPostCoverURL(value)}})])],1)})],2)]}}])})],1)]),_c('div',{staticClass:"col-lg-6 col-md-6 col-xs-6 pd-l-10 pl-0"},[_c('section',{attrs:{"id":"page-news-publish"}},[_c('div',{staticClass:"tab newshot"},[_c('a-tabs',{attrs:{"default-active-key":"1"}},[_c('a-tab-pane',{key:"1",attrs:{"tab":"司法行政要闻"}},[_c('div',{staticClass:"tab-pane"},[_c('Collection',{attrs:{"name":"post","query":{ target: '0176141e93568a8380377603faf907ff' },"limit":9},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var collection = ref.collection;
return [_c('Article',{attrs:{"refObj":collection}})]}}])})],1)]),_c('a-tab-pane',{key:"2",attrs:{"tab":"普法动态"}},[_c('div',{staticClass:"tab-pane"},[_c('Collection',{attrs:{"name":"post","query":{ target: '0176141ed1ea8a8380377603faf90801' },"limit":9},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var collection = ref.collection;
return [_c('Article',{attrs:{"refObj":collection}})]}}])})],1)])],1)],1)])]),_c('div',{staticClass:"col-lg-12 col-md-12 col-xs-12 p-0 mt-3"},[_c('div',{staticClass:"tab float-left w-news-tab col-lg-6 col-md-6 pl-0"},[_c('a-tabs',{attrs:{"default-active-key":"1"}},[_c('a-tab-pane',{key:"1",attrs:{"tab":"公证动态"}},[_c('div',{staticClass:"tab-pane"},[_c('Collection',{attrs:{"name":"post","query":{ target: '0176891df0ed8a8381287688095907b4' },"limit":9},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var collection = ref.collection;
return [_c('Article',{attrs:{"refObj":collection}})]}}])})],1)]),_c('a-tab-pane',{key:"2",attrs:{"tab":"法援动态"}},[_c('div',{staticClass:"tab-pane"},[_c('Collection',{attrs:{"name":"post","query":{ target: '0176891d17178a8381287688095907b2' },"limit":9},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var collection = ref.collection;
return [_c('Article',{attrs:{"refObj":collection}})]}}])})],1)]),_c('a-tab-pane',{key:"3",attrs:{"tab":"律师动态"}},[_c('div',{staticClass:"tab-pane"},[_c('Collection',{attrs:{"name":"post","query":{ target: '0176893ec6a58a838128768809590a43' },"limit":9},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var collection = ref.collection;
return [_c('Article',{attrs:{"refObj":collection}})]}}])})],1)])],1)],1),_c('div',{staticClass:"tab float-right w-news-tab col-lg-6 col-md-6 pr-0"},[_c('a-tabs',{attrs:{"default-active-key":"1"}},[_c('a-tab-pane',{key:"1",attrs:{"tab":"司鉴动态"}},[_c('div',{staticClass:"tab-pane"},[_c('Collection',{attrs:{"name":"post","query":{ target: '0176893f2d9c8a838128768809590a47' },"limit":9},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var collection = ref.collection;
return [_c('Article',{attrs:{"refObj":collection}})]}}])})],1)]),_c('a-tab-pane',{key:"2",attrs:{"tab":"法考动态"}},[_c('div',{staticClass:"tab-pane"},[_c('Collection',{attrs:{"name":"post","query":{ target: '0176898ef7c98a838128768809590cde' },"limit":9},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var collection = ref.collection;
return [_c('Article',{attrs:{"refObj":collection}})]}}])})],1)])],1)],1)]),_c('div',{staticClass:"col-lg-12 col-md-12 col-xs-12 p-0 mb-3"},[_c('div',{staticClass:"col-sm-tjss pull-left m-0"},[_c('div',{staticClass:"tab"},[_c('ul',{staticClass:"tab-heade"},[_c('li',{staticClass:"active"},[_c('router-link',{attrs:{"to":"/article/list/0176141f4d428a8380377603faf90804"}},[_vm._v("公法图解")])],1)]),_c('div',{staticClass:"tab-content"},[_c('Collection',{attrs:{"name":"post","query":{ target: '0176141f4d428a8380377603faf90804' },"limit":1},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var collection = ref.collection;
return [_c('Articlepic',{attrs:{"refObj":collection}})]}}])})],1)])]),_c('div',{staticClass:"col-sm-swsp pull-left"},[_c('div',{staticClass:"tab"},[_c('ul',{staticClass:"tab-heade"},[_c('li',{staticClass:"active"},[_c('router-link',{attrs:{"to":"/article/list/0176141f8f438a8380377603faf90806"}},[_vm._v("公法视频")])],1)]),_c('div',{staticClass:"tab-content"},[_c('Collection',{attrs:{"name":"post","query":{ target: '0176141f8f438a8380377603faf90806' },"limit":1},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var collection = ref.collection;
return [_c('Articlepic',{attrs:{"refObj":collection}})]}}])})],1)])]),_c('div',{staticClass:"col-sm-xmt pull-right threetab"},[_c('div',{staticClass:"tab"},[_c('ul',{staticClass:"tab-heade"},[_c('li',{staticClass:"active"},[_c('router-link',{attrs:{"to":"/article/list/0176141fb0ab8a8380377603faf90808"}},[_vm._v("常见问题解答")])],1)]),_c('div',{staticClass:"tab-content"},[_c('div',{staticClass:"tab-pane active"},[_c('Collection',{attrs:{"name":"post","query":{ target: '0176141fb0ab8a8380377603faf90808' },"limit":6},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var collection = ref.collection;
return [_c('Article',{attrs:{"refObj":collection}})]}}])})],1)])])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }