<template>
<div class="tab-pane active">
  <div v-for="value in refObj" :key = "value.post_id">
   <router-link :to="`/article/detail/${value.post_id}`" :title="value.post_title">
    <img :src="getPostCoverURL(value)">
        <p>{{ value.post_title }}</p>
 </router-link>
</div>
 </div>
</template>
<script>
import base from '@/core/base.js'
export default {
  name: 'Articlepic',
  mixins: [base]
}
</script>
