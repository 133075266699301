<template>
  <Page :title="'服务动态'">
    <article id="page-news">
      <div class="public-blue-bg news-banner-bg">
        <div class="container">
        <h1>公共法律服务动态</h1>
          <div class="text">
            公共法律服务是政府公共职能的重要组成部分，是保障和改善民生的重要举措，是全面依法治国的基础性、服务性和保障性工作。
          </div>
        </div>
      </div>
      <section class="container container-main">
        <div class="row mt-5">
          <div class="col-lg-6 col-md-6 col-xs-6 pd-r-10 pl-0">
            <section class="swiper-container comp-focus"  id="page-index-focus">
              <Collection  name="post" :query="{ sort_no: '100'}"  v-slot="{ collection }"  :limit=9 >
              <a-carousel arrows>
                <div slot="prevArrow" class="custom-slick-arrow">
                  <a-icon type="left" />
                </div>
                <div slot="nextArrow" class="custom-slick-arrow">
                  <a-icon type="right" />
                </div>
                <div class="swiper-slide" v-for="value in collection" :key="value.post_id">
                  <router-link  :to="`/article/detail/${value.post_id}`" :title="value.post_title"><img :src="getPostCoverURL(value)"></router-link>
                </div>
              </a-carousel>
            </Collection>
            </section>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-6 pd-l-10 pl-0">
            <section id="page-news-publish">
              <div class="tab newshot">
                 <a-tabs default-active-key="1">
                   <a-tab-pane key="1" tab="司法行政要闻">
                      <div  class="tab-pane">
                        <Collection  name="post" :query="{ target: '0176141e93568a8380377603faf907ff' }"
                          v-slot="{ collection }"  :limit=9 >
                        <Article :refObj="collection" ></Article>
                        </collection>
                      </div>
                   </a-tab-pane>
                    <a-tab-pane key="2" tab="普法动态">
                      <div  class="tab-pane">
                      <Collection  name="post"   :query="{ target: '0176141ed1ea8a8380377603faf90801' }"
                        v-slot="{ collection }"  :limit=9 >
                      <Article :refObj="collection"></Article>
                      </collection>
                      </div>
                    </a-tab-pane>
                 </a-tabs>
              </div>
            </section>
          </div>
          <div class="col-lg-12 col-md-12 col-xs-12 p-0 mt-3">
            <div class="tab float-left w-news-tab col-lg-6 col-md-6 pl-0">
               <a-tabs default-active-key="1">
                   <a-tab-pane key="1" tab="公证动态">
                     <div  class="tab-pane">
                    <Collection  name="post" :query="{ target: '0176891df0ed8a8381287688095907b4' }"
                      v-slot="{ collection }"  :limit=9 >
                    <Article :refObj="collection"></Article>
                    </collection>
                    </div>
                   </a-tab-pane>
                    <a-tab-pane key="2" tab="法援动态">
                      <div  class="tab-pane">
                   <Collection  name="post"   :query="{ target: '0176891d17178a8381287688095907b2' }"
                    v-slot="{ collection }"  :limit=9 >
                   <Article :refObj="collection"></Article>
                   </collection>
                  </div>
                    </a-tab-pane>
                     <a-tab-pane key="3" tab="律师动态">
                      <div  class="tab-pane">
                   <Collection  name="post"   :query="{ target: '0176893ec6a58a838128768809590a43' }"
                    v-slot="{ collection }"  :limit=9 >
                   <Article :refObj="collection"></Article>
                   </collection>
                  </div>
                    </a-tab-pane>
                 </a-tabs>
            </div>
            <div class="tab float-right w-news-tab col-lg-6 col-md-6 pr-0">
               <a-tabs default-active-key="1">
                   <a-tab-pane key="1" tab="司鉴动态">
                     <div  class="tab-pane">
                    <Collection  name="post" :query="{ target: '0176893f2d9c8a838128768809590a47' }"
                      v-slot="{ collection }"  :limit=9 >
                    <Article :refObj="collection"></Article>
                    </collection>
                    </div>
                   </a-tab-pane>
                    <a-tab-pane key="2" tab="法考动态">
                      <div  class="tab-pane">
                   <Collection  name="post"   :query="{ target: '0176898ef7c98a838128768809590cde' }"
                    v-slot="{ collection }"  :limit=9 >
                   <Article :refObj="collection"></Article>
                   </collection>
                  </div>
                    </a-tab-pane>
                 </a-tabs>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-xs-12 p-0 mb-3">
            <div class="col-sm-tjss pull-left m-0">
              <div class="tab">
                <ul class="tab-heade">
                  <li class="active"><router-link to="/article/list/0176141f4d428a8380377603faf90804">公法图解</router-link></li>
                </ul>
                <div class="tab-content">
                   <Collection  name="post"   :query="{ target: '0176141f4d428a8380377603faf90804' }"
                    v-slot="{ collection }"  :limit=1 >
                   <Articlepic :refObj="collection"></Articlepic>
                   </collection>
                </div>
              </div>
            </div>
            <div class="col-sm-swsp pull-left">
              <div class="tab">
                <ul class="tab-heade">
                  <li class="active"><router-link to="/article/list/0176141f8f438a8380377603faf90806">公法视频</router-link></li>
                </ul>
                <div class="tab-content">
                   <Collection  name="post"   :query="{ target: '0176141f8f438a8380377603faf90806' }"
                    v-slot="{ collection }"  :limit=1 >
                   <Articlepic :refObj="collection"></Articlepic>
                   </collection>
                </div>
              </div>
            </div>
            <div class="col-sm-xmt pull-right threetab">
              <div class="tab">
                <ul class="tab-heade">
                  <li class="active"><router-link to="/article/list/0176141fb0ab8a8380377603faf90808">常见问题解答</router-link></li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane active">
                    <Collection  name="post"   :query="{ target: '0176141fb0ab8a8380377603faf90808' }"
                    v-slot="{ collection }"  :limit=6 >
                   <Article :refObj="collection"></Article>
                   </collection>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>
  </Page>
</template>
<script>
import Collection from '../components/Collection.vue'
import Article from '../components/base/Article.vue'
import Articlepic from '../components/base/Articlepic.vue'

export default {
  name: 'news',
  components: {
    Collection,
    Article,
    Articlepic
  }
}
</script>
<style scoped src="../assets/css/news.css"></style>
